<template>
  <router-view />
  <div class="form-wrapper">
    <!-- logo -->
    <div id="logo">
      <img
        class="logo"
        src="@/assets/image/logo-gesdoc-overdrive.png"
        alt="image"
      />
    </div>
    <!-- ./ logo -->

    <h5>gesdoc overdrive <span>beta</span></h5>

    <p v-if="!changed">Entre com sua nova senha abaixo:</p>

    <!-- login form -->
    <form @submit.prevent="submit" v-if="!changed">
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Nova senha"
          required
          v-model="password"
          :disabled="validating"
          :class="{error: !valid}"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Confirmação da senha"
          required
          v-model="password2"
          :disabled="validating"
          :class="{error: !valid}"
        />
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-block"
        :disabled="validating"
      >
        Trocar senha
      </button>
      <pulse v-if="validating" />
    </form>

    <p v-if="changed">
      Sua senha foi alterada e você já pode acessar o sistema.
    </p>

    <p v-if="changed">
      <button
        class="btn btn-primary btn-block"
        @click="login"
      >
        Acessar
      </button>
    </p>

    <div class="mt-4">
      <p>2023 &copy; (PC)² inteligência tecnológica.</p>
    </div>
    <!-- ./ form -->
  </div>
</template>

<script>
import Pulse from "@/components/Pulse.vue";
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: {
    Pulse
  },
  data() {
    return {
      token     : this.$route.query.token,
      email     : "",
      password  : "",
      password2 : "",
      valid     : true,
      validating: false,
      changed   : false
    };
  },
  computed: {
    ...mapGetters(['apiEndpoint'])
  },
  methods: {
    validateForm() {
      this.valid = true;

      if (this.password != this.password2) {
        this.valid = false;
      }
    },
    async submit() {
      this.validating = true;

      this.validateForm();

      if (!this.valid) {
        this.validating = false;
        return;
      }

      const data = {
        token: this.token,
        password: this.password,
      };

      try {
        await axios.post(`${this.apiEndpoint}/users/newPassword`, data);
        this.changed = true;
      } catch {
        this.$toast.add({
          severity: "error",
          summary : "Houve um erro ao alterar a sua senha.",
          life    : 3000,
        });
      } finally {
        this.validating = false;
      }
    },
    login() {
      this.$router.push('/login');
    }
  },
  mounted() {
    document.body.classList.add("form-membership");

    // Check if token exists
    if (!this.token) {
      this.login();
    }
  }
}
</script>
