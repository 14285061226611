<template>
<div class="loadingio-spinner-pulse-knl19por64f"><div class="ldio-aa8ws9iusnp">
<div></div><div></div><div></div>
</div></div>
</template>

<style scoped>
@keyframes ldio-aa8ws9iusnp-1 {
  0% { top: 18px; height: 64px }
  50% { top: 30px; height: 40px }
  100% { top: 30px; height: 40px }
}
@keyframes ldio-aa8ws9iusnp-2 {
  0% { top: 20.999999999999996px; height: 58.00000000000001px }
  50% { top: 30px; height: 40px }
  100% { top: 30px; height: 40px }
}
@keyframes ldio-aa8ws9iusnp-3 {
  0% { top: 24px; height: 52px }
  50% { top: 30px; height: 40px }
  100% { top: 30px; height: 40px }
}
.ldio-aa8ws9iusnp div { position: absolute; width: 15px }.ldio-aa8ws9iusnp div:nth-child(1) {
  left: 17.5px;
  background: #292664;
  animation: ldio-aa8ws9iusnp-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.2s
}
.ldio-aa8ws9iusnp div:nth-child(2) {
  left: 42.5px;
  background: #667395;
  animation: ldio-aa8ws9iusnp-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.1s
}
.ldio-aa8ws9iusnp div:nth-child(3) {
  left: 67.5px;
  background: #94a9ce;
  animation: ldio-aa8ws9iusnp-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}

.loadingio-spinner-pulse-knl19por64f {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-aa8ws9iusnp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.8);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-aa8ws9iusnp div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>